<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  :color="GRUPO_CHECKLIST.colorGrupo.generales"
                  dark
                >
                  G
                </v-chip>
              </td>
              <td>
                Grupo de preguntas generales
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="GRUPO_CHECKLIST.colorGrupo.subsistemas"
                  dark
                >
                  S
                </v-chip>
              </td>
              <td>
                Grupo de preguntas de subsistema
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="GRUPO_CHECKLIST.colorGrupo.materiales"
                  dark
                >
                  M
                </v-chip>
              </td>
              <td>
                Grupo de preguntas de material
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="grey"
                  dark
                  label
                >
                  x%
                </v-chip>
              </td>
              <td>
                Parcialmente contestado
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="error"
                  dark
                  label
                >
                  x%
                </v-chip>
              </td>
              <td>
                Con anomalías
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="success"
                  dark
                  label
                >
                  100%
                </v-chip>
              </td>
              <td>
                Contestado y correcto
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import { GRUPO_CHECKLIST, CHECKLIST } from '@/utils/consts'
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      CHECKLIST,
      GRUPO_CHECKLIST
    }
  }
}
</script>
