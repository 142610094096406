import _ from '@/utils/lodash'
import { GRUPO_CHECKLIST } from '@/utils/consts'

export default {
  async selectChecklistOt (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.checklist_ot)
      .innerJoin(
        tables.checklist,
        tables.checklist_ot.idchecklist.eq(tables.checklist.idchecklist)
      )
      .innerJoin(
        tables.orden_trabajo,
        tables.checklist_ot.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.checklist_ot.idchecklist_ot.eq(idchecklistOt),
        )
      )
      .exec())[0]
  },
  async rowParteTrabajo (Vue, idparteTrabajo) {
    return Vue.$offline.parteTrabajo.row(idparteTrabajo)
  },
  _selectCountPreguntasBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas')
      )
      .from(tables.grupo_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
      )
  },
  _selectCountPreguntasNoContestadasGrupo (Vue, idgrupoChecklistOt) {
    const tables = Vue.$offline.db.tables
    return this._selectCountPreguntasBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
          tables.pregunta_checklist_ot.idgrado_anomalia.isNull()
        )
      )
      .exec()
  },
  _selectCountPreguntasContestadasGrupo (Vue, idgrupoChecklistOt) {
    const tables = Vue.$offline.db.tables
    return this._selectCountPreguntasBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
          tables.pregunta_checklist_ot.idgrado_anomalia.isNotNull()
        )
      )
      .exec()
  },
  async _addSubqueriesGrupos (Vue, rows, clasificacion) {
    const tables = Vue.$offline.db.tables
    for (const row of rows) {
      // preguntas
      const contestadas = await this._selectCountPreguntasContestadasGrupo(
        Vue, row.grupo_checklist_ot.idgrupo_checklist_ot
      )
      const preguntasContestadas = contestadas[0].cant_preguntas
      const noContestadas = await this._selectCountPreguntasNoContestadasGrupo(
        Vue, row.grupo_checklist_ot.idgrupo_checklist_ot
      )
      const preguntasNoContestadas = noContestadas[0].cant_preguntas
      row.porcentajeProgreso = Math.round(
        preguntasContestadas * 100 / (preguntasNoContestadas + preguntasContestadas)
      )
      // resultado grupo
      const resultadoGrupo = await Vue.$offline.grupoChecklistOt.resultadoGrupo(
        row.grupo_checklist_ot.idgrupo_checklist_ot
      )
      row.resultado = resultadoGrupo
      if (row.resultado === GRUPO_CHECKLIST.resultado.ok) {
        row.color = GRUPO_CHECKLIST.colorResultado.ok
        row.resultadoDesc = GRUPO_CHECKLIST.resultadoDesc.ok
      } else if (row.resultado === GRUPO_CHECKLIST.resultado.conAnomalias) {
        row.color = GRUPO_CHECKLIST.colorResultado.conAnomalias
        row.resultadoDesc = GRUPO_CHECKLIST.resultadoDesc.conAnomalias
      } else {
        row.color = GRUPO_CHECKLIST.colorResultado.sinContestar
        row.resultadoDesc = GRUPO_CHECKLIST.resultadoDesc.sinContestar
      }
      // cantidad de materiales
      if (clasificacion === GRUPO_CHECKLIST.clasificacion.materiales) {
        const cantMateriales = await Vue.$offline.db
          .select(
            tables.grupo_checklist_ot.idgrupo_checklist_ot,
            tables.material_sistema.idmaterial_sistema,
            tables.material_sistema.unidades,
            Vue.$offline.db.fn.count(tables.grupo_checklist_ot.idgrupo_checklist_ot).as('dummy')
          )
          .from(tables.grupo_checklist_ot)
          .innerJoin(
            tables.pregunta_checklist_ot,
            tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
          )
          .innerJoin(
            tables.orden_trabajo_matsist,
            tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
          )
          .innerJoin(
            tables.material_sistema,
            tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
          )
          .innerJoin(
            tables.subsis,
            tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
          )
          .where(
            Vue.$offline.db.op.and(
              tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(row.grupo_checklist_ot.idgrupo_checklist_ot),
            )
          )
          .groupBy(
            tables.grupo_checklist_ot.idgrupo_checklist_ot,
            tables.material_sistema.idmaterial_sistema,
            tables.material_sistema.unidades,
          )
          .exec()
        row.cantMateriales = _.sumBy(cantMateriales, 'material_sistema.unidades')
      }
      // letra avatar del detail
      if (clasificacion === GRUPO_CHECKLIST.clasificacion.generales) {
        row.avatar = 'G'
        row.avatarColor = GRUPO_CHECKLIST.colorGrupo.generales
      } else if (clasificacion === GRUPO_CHECKLIST.clasificacion.subsistemas) {
        row.avatar = 'S'
        row.avatarColor = GRUPO_CHECKLIST.colorGrupo.subsistemas
      } else {
        row.avatar = 'M'
        row.avatarColor = GRUPO_CHECKLIST.colorGrupo.materiales
      }
    }
  },
  async selectGruposGeneral (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    const rows = await Vue.$offline.db
      .select(
        tables.grupo_checklist_ot.idgrupo_checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot,
        tables.grupo_checklist_ot.descripcion,
        tables.grupo_checklist_ot.clasificacion,
        tables.grupo_checklist_ot.orden,
        Vue.$offline.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas')
      )
      .from(tables.grupo_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.generales)
        )
      )
      .groupBy(
        tables.grupo_checklist_ot.idgrupo_checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot,
        tables.grupo_checklist_ot.descripcion,
        tables.grupo_checklist_ot.orden
      )
      .orderBy(tables.grupo_checklist_ot.orden)
      .exec()
    await this._addSubqueriesGrupos(Vue, rows, GRUPO_CHECKLIST.clasificacion.generales)
    return rows
  },
  async selectGruposSubsistema (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    // no se filtra por subsistemas afectados porque no hay una tabla PARTE_MONTAJE_SUBSIS
    // tirando de PARTE_MONTAJE_MATSIS -> ORDEN_TRABAJO_MATSIST -> MATERIAL_SISTEMA -> SUBSIS
    // solo muestra las preguntas de subsistema sin hay material
    const rows = await Vue.$offline.db
      .select(
        tables.grupo_checklist_ot.idgrupo_checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot,
        tables.grupo_checklist_ot.descripcion,
        tables.grupo_checklist_ot.clasificacion,
        tables.grupo_checklist_ot.orden,
        tables.grupo_checklist_ot.idchecklist_ot_subsis,
        tables.subsis.descripcion.as('desc_subsis'),
        Vue.$offline.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas')
      )
      .from(tables.grupo_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot_subsis,
        tables.grupo_checklist_ot.idchecklist_ot_subsis.eq(tables.checklist_ot_subsis.idchecklist_ot_subsis)
      )
      .innerJoin(
        tables.subsis,
        tables.checklist_ot_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.subsistemas),
        )
      )
      .groupBy(
        tables.grupo_checklist_ot.idgrupo_checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot,
        tables.grupo_checklist_ot.descripcion,
        tables.grupo_checklist_ot.idchecklist_ot_subsis,
        tables.grupo_checklist_ot.orden
      )
      .orderBy(tables.grupo_checklist_ot.orden)
      .exec()
    await this._addSubqueriesGrupos(Vue, rows, GRUPO_CHECKLIST.clasificacion.subsistemas)
    return rows
  },
  async selectGruposMaterial (Vue, idchecklistOt, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const inSubsisAfectados = _.map(
      await Vue.$offline.parteTrabajoMatsist.selectSubsisAfectados(
        idparteTrabajo
      ), 'idsubsis'
    )
    const rows = await Vue.$offline.db
      .select(
        tables.grupo_checklist_ot.idgrupo_checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot,
        tables.grupo_checklist_ot.descripcion,
        tables.grupo_checklist_ot.clasificacion,
        tables.grupo_checklist_ot.orden,
        tables.grupo_checklist_ot.idchecklist_ot_subsis,
        tables.subsis.descripcion.as('desc_subsis'),
        Vue.$offline.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas'),
      )
      .from(tables.grupo_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.materiales),
          tables.material_sistema.idsubsis.in(inSubsisAfectados)
        )
      )
      .groupBy(
        tables.grupo_checklist_ot.idgrupo_checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot,
        tables.grupo_checklist_ot.descripcion,
        tables.grupo_checklist_ot.idchecklist_ot_subsis,
        tables.grupo_checklist_ot.orden
      )
      .orderBy(tables.grupo_checklist_ot.orden)
      .exec()
    await this._addSubqueriesGrupos(Vue, rows, GRUPO_CHECKLIST.clasificacion.materiales)
    return rows
  },
}
