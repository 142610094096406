export default () => {
  return {
    marcarTodoCorrecto: {
      title: 'Marcar todo correcto',
      visible: true,
      icon: 'checkAll'
    },
    pendiente: {
      title: 'Pasar a pendiente',
      visible: true,
      icon: 'undo'
    },
    finalizar: {
      title: 'Finalizar',
      visible: true,
      icon: 'finalizar'
    },
    resumenYFirma: {
      title: 'Resumen y firma',
      visible: true,
      icon: 'signature'
    },
    parteTrabajo: {
      title: 'Ir a parte de trabajo',
      visible: true
    },
    irOrden: {
      title: 'Ir a la orden de trabajo',
      visible: false,
    },
    iniciarCrono: {
      title: 'Iniciar cronómetro',
      visible: true,
      icon: 'cronometro'
    },
    detenerCrono: {
      title: 'Detener cronónometro',
      visible: true,
      icon: 'cronometro'
    },
    legend: {
      title: 'Colores y leyendas',
      visible: true,
      icon: 'info'
    },
  }
}
